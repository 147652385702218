/*************************************************************************
GLOBAL STYLES
*************************************************************************/
.pretty-bar {
  width: 100vw;
  height: 500px;
  background-image: linear-gradient(to right, #c50, #005f69);
}

@font-face {
  font-family: 'Alba';
  src: url(../fonts/ALBA____.TTF);
}

@font-face {
  font-family: 'AlbaS';
  src: url(../fonts/ALBAS___.TTF);
}

/* @font-face {
  font-family: "AlbaM";
  src: url(../fonts/ALBAm___.TTF);
} */

@font-face {
  font-family: 'Hobo';
  src: url(../fonts/HoboStd.otf);
}

html {
  box-sizing: border-box;
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Alba', Tahoma, Geneva, Verdana, sans-serif;
  background-color: ivory;
  color: black;
}

img {
  display: block;
}

p {
  line-height: 1.33em;
  font-family: 'Hobo';
}

.hobo {
  font-family: 'Hobo';
}

footer {
  /* height: 60px; */
  padding: 8px 0;
  /* margin-top: 15px; */
  background-color: darkgoldenrod;
  font-size: 0.75em;
  color: goldenrod;
  text-align: center;
}

footer a.footer-text {
  color: goldenrod;
  margin: 0;
  text-decoration: none;
}

/* footer > p { */
/* margin: 15px 0 0 0; */
/* } */

h1,
h2,
h3,
h4 {
  font-family: 'AlbaS';
  margin: 5% 0;
}

h1,
h2,
i {
  color: turquoise;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 1.5em;
  color: goldenrod;
}

h4 {
  font-family: 'Alba';
  font-size: 1.33em;
  background-color: turquoise;
  padding: 1%;
  color: azure;
}

i {
  font-size: 1.5rem;
  color: ivory;
}

hr {
  color: white;
  width: 75%;
  margin: auto;
}

a,
a:visited {
  color: darkgoldenrod;
}

a.button {
  display: inline-block;
  /* height: 40px; */
  width: 120px;
  padding: 0.8em;
  border: 0;
  border-radius: 0.25rem;
  background: goldenrod;
  color: white;
  font-size: 0.8rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  margin: 0.5em 0;
  /* margin-right: 0.5rem; */
  box-shadow: 1px 1px 2px #000;
  cursor: pointer;
}

a.button-outline {
  border: 2px solid white;
  background: transparent;
  text-shadow: 1px 1px 1px #000;
}

a.button:hover,
a.button:focus {
  background: darkturquoise;
}

a.button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

a.button:active {
  transform: scale(0.98);
  box-shadow: none;
  transition: 0.25s ease;
}

.btn-group a.button {
  margin-right: 1em;
}

/* button > a {
  display: block;
  width: 120px;
  height: 40px;
  text-align: center;
} */

/* .button-group {
  justify-content: space-between;
} */

.center-text {
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 75%;
}

.full-width-img {
  width: 100%;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

/*************************************************************************
PAGE STYLES
*************************************************************************/

/***************************************************
Home Page
***************************************************/
.fullscreen-bg-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -100;
}

.over-video {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20vh;
  /* flex-direction: column; */
  /* position: absolute;
  top: 20vh;
  left: 25%; */
}

.text-overlay {
  margin: auto;
  /* background-color: #daa52088; */
  background-color: #00000044;
  max-width: 800px;
  /* margin: 5vh auto 20px; */
  text-align: center;
  padding: 1.2em;
  border-radius: 10px;
  color: white;
  box-shadow: 1px 1px 2px #000;
  text-shadow: 1px 1px 1px #000;
}

.btn-group {
  /* display: flex; */
  width: 100vw;
  text-align: center;
  margin: 2% auto;
}

/** For displaying the video controls over the fullscreen video section */
.video-controls {
  transition: none;
  padding: 1em 2em;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  color: white;
  background-image: linear-gradient(to bottom, #00000000, #000000ff);
}

.video-controls svg {
  font-size: 3em;
  /* Since SVG's aren't like other text, the shadow can only be applied via filter: drop-shadow() */
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.video-controls svg:hover {
  font-size: 3.1em;
  /* padding-bottom: 0.5em; */
}

#volume_slider {
  max-width: 12%;
  min-width: 60px;
  margin: 0 2em 0 auto;
}

#seek_slider {
  width: 100%;
}

input[type='range'] {
  -webkit-appearance: none;
  margin: 1em 0;
  width: 100%;
  /* height: 100px; */
  background: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: darkgoldenrod;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background-image: url('../imgs/disco-ball-152.png');
  background-position: center;
  background-size: contain;
  /* background: #ffffff; */
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.666rem;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: darkgoldenrod;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: darkgoldenrod;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-image: url('../imgs/disco-ball-152.png');
  background-position: center;
  background-size: contain;
  /* background: #ffffff; */
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  /* height: 8.4px; */
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: darkgoldenrod;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  background: darkgoldenrod;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff00;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: darkgoldenrod;
}
input[type='range']:focus::-ms-fill-upper {
  background: darkgoldenrod;
}

/** For hiding the video controls over the fullscreen video section. This class is applied via JS. See Home.jsx */
.hide-video-controls,
.hide-video-controls > input#seek_slider {
  transition: 1s ease-in;
  /* color: transparent;
  background: transparent;
  border: none; */
  opacity: 0;
}

/* #play_button {
  font-size: 1.5em;
  padding: 2.5%;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border: 2px solid white;
  background: transparent;
  border-radius: 50%;
  box-shadow: 1px 1px 2px #000;
  text-shadow: 1px 1px 1px #000;
} */

/***************************************************
About Page
***************************************************/

.full-width-img {
  width: 100%;
  margin: 0;
  padding: 0;
}

#meet_the_band {
  text-align: center;
  font-size: 3em;
}

#abba_page_logo_display {
  display: block;
  /* display: none; */
  width: 50%;
  max-width: 90%;
  margin: -23.3% auto 0;
}

div#band_bio {
  font-size: 1.5em;
  margin: 2.5% auto 80px auto;
  line-height: 1.33em;
}
div#band_bio h2 {
  font-size: 2em;
}

.bio {
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5%;
  border-bottom: 1px solid darkgray;
}

.bio > p {
  font-size: 0.75em;
}

.bio-pic {
  max-width: 240px;
  border-radius: 50%;
  margin: 10px;
}

/* .border-top {
  border-top: 1px solid darkgray;
}
.border-right {
  border-right: 1px solid darkgray;
}
.border-bottom {
  border-bottom: 1px solid darkgray;
}
.border-left {
  border-left: 1px solid darkgray;
} */

/***************************************************
Events Page
***************************************************/

.events-bg {
  padding: 80px 0;
  min-width: 100%;
  min-height: calc(100vh - 38px);
  background-image: url(../imgs/fabba-15.jpg);
  background-size: cover;
  background-position: center;
  text-align: center;
}

.events-bg > h1 {
  margin: 10vh 0;
  font-family: 'Alba';
  color: ivory;
  text-shadow: 4px 4px goldenrod;
}

.event {
  display: grid;
  margin-bottom: 20px;
  color: white;
  text-shadow: 1px 1px 1px #000;
  background-color: #111111aa;
  box-shadow: 2px 2px 2px #000000;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5em;
}

.event-venue-name {
  font-size: 1.2em;
}

a.venue-link {
  color: darkgoldenrod;
  font-size: 0.8em;
  margin: 1.5em;
}

.event-date {
  grid-row: 1/3;
}

.event-date-number {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 3em;
}

.event-month-small {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.event-title {
  font-size: 1.5em;
  margin: 0.5em;
}

.event-venue {
  font-size: 0.8em;
}

/* .event-title {
  grid-row: 1/2;
  grid-column: 2/4;
}

.event-venue {
  grid-row: 2/3;
  grid-column: 2/3;
}

.event-ticket-link {
  margin: auto;
  grid-row: 1/3;
  grid-column: 3/4;
} */

/***************************************************
Booking Page
***************************************************/

#contact_info,
#contact_section {
  background-color: white;
}

p.contact {
  margin-bottom: 5%;
}

#contact_info > img:last-child {
  margin-bottom: -5px; /* Makes the image at the bottom of the page flush with the footer */
}

#now_booking {
  margin-top: 5%;
}

.virtual-tour-info {
  margin: 0 auto 5% auto;
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 5%;
}

.option {
  text-align: left;
  list-style: outside;
}

.option p {
  margin-bottom: 5%;
}

.option ul li {
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 0.9em;
  margin-bottom: 2%;
}

.virtual-tour-info h2,
.virtual-tour-info h3 {
  width: 75%;
  margin: 1% auto;
  padding: 0;
}

.span-all-cols {
  grid-column: 1 / -1;
  /* grid-row: 1 / 2; */
}

/*************************************************************************
GALLERY PAGE
*************************************************************************/
#gallery_page {
  margin-top: 10em;
}

#gallery_page h1 {
  text-align: center;
}

.wrapper-images {
  /* background-color: red; */
  margin-bottom: 3em;
}

.wrapper-images h3,
.wrapper-images p {
  text-align: center;
  margin: 0 auto;
  padding: 0.3333em 0;
  /* line-height: 0.33em; */
  max-width: 60ch;
}

.gallery {
  /* max-width: 80; */
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
  /* gap: 2em; */
}

.gallery img {
  cursor: pointer;
  max-width: 100%;
  padding: 0;
  margin: 1em auto 0;
  /* margin: 0 auto; */
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  z-index: 200;
}
.overlay img {
  display: block;
  max-width: 95%;
  max-height: 95%;
  margin: auto;
  z-index: 300;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > svg.dismiss {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 400;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 400;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 400;
}


/*************************************************************************
MEDIA QUERIES
*************************************************************************/

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 200px) and (max-width: 767px) {
  /* Styles */

  .hide-on-mobile {
    display: none;
  }

  html {
    font-size: 1.4rem;
  }

  .container {
    max-width: 90%;
  }

  .footer-text {
    font-size: 1em;
  }

  .events-bg h1 {
    font-size: 2.5em;
  }

  .text-overlay p,
  .over-video .btn-group {
    font-size: 0.75em;
  }
  #contact_info > h1 {
    font-size: 1.8em;
  }
  div#band_bio {
    font-size: 1em;
  }
  div#band_bio h2 {
    font-size: 2em;
  }
  .option {
    grid-column: 1 / -1;
  }
}

/* Tablets (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1223px) {
  /* Styles */

  .show-on-mobile {
    display: none;
  }

  html {
    font-size: 1.4rem;
  }

  .container {
    max-width: 90%;
  }

  div#band_bio {
    font-size: 1em;
  }
  div#band_bio h2 {
    font-size: 2em;
  }

  .bios {
    grid-template-columns: 1fr 1fr;
    /* grid-gap: 1px; */
  }
  .bio:nth-last-child(2),
  .bio:last-child {
    border-bottom: none;
  }
  .bio:nth-child(odd) {
    border-right: 1px solid darkgray;
  }
  #band_bio {
    max-width: 90%;
    margin: auto;
  }
  .event {
    grid-template-columns: minmax(15%, 33%) minmax(33%, 70%) minmax(15%, 33%);
    grid-template-rows: repeat(2, 1fr);
  }
  .event-ticket-link {
    grid-row: 1/3;
    grid-column: 3/4;
    margin: auto;
  }
}

/* iPads (landscape) ----------- */
/* @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { */
/* Styles */
/* } */

/* iPads (portrait) ----------- */
/* @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) { */
/* Styles */
/* } */

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */

  .show-on-mobile {
    display: none;
  }

  /* Hide the side-nav-opening hamburger menu and show the regular navigation links  */
  /* #nav-toggle-item {
    display: none;
  } */
  .main-nav {
    display: flex;
  }

  .bios {
    grid-template-columns: 1fr 1fr;
  }
  .bio {
    flex-direction: row;
    justify-content: flex-end;
  }
  img.bio-pic {
    flex-shrink: 0;
  }
  .bio:nth-last-child(2),
  .bio:last-child {
    border-bottom: none;
  }
  .bio:nth-child(odd) {
    border-right: 1px solid darkgray;
  }
  #band_bio {
    /* max-width: 75%; */
    margin: auto;
  }
  .event {
    grid-template-columns: minmax(15%, 33%) minmax(33%, 70%) minmax(15%, 33%);
    grid-template-rows: repeat(2, 1fr);
  }
  .event-ticket-link {
    grid-row: 1/3;
    grid-column: 3/4;
    margin: auto;
  }
  #events_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
}

/* Large screens ----------- */
/* @media only screen and (min-width: 1824px) { */
/* Styles */
/* } */

/* iPhone 4 ----------- */
/* @media only screen and (-webkit-min-device-pixel-ratio: 1.5), */
/* only screen and (min-device-pixel-ratio: 1.5) { */
/* Styles */
/* } */
