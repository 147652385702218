* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.2em;
}

nav {
  --main-bg-color: black;
  --alt-bg-color: #daa520;
  --text-color: azure;
  --container-width: 75%;
  --side-sheet-width: 66.6667vw;
  --navbar-height: 12vh;
  --transition: all 0.5s ease-out;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  text-decoration: none;
}
nav a,
nav a:visited {
  color: var(--text-color);
  text-decoration: none;
}
nav a:focus,
nav a:active {
  padding: 0.33em 0;
  outline: 2px solid var(--alt-bg-color);
}
nav .material-icons {
  font-size: 2em;
}

#navbar {
  transition: var(--transition);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  text-shadow: 1px 1px 1px #000;
  /* background-image: linear-gradient(to right, black, darkgoldenrod); */
}
#navbar ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--container-width);
  margin: auto;
  list-style: none;
}
#navbar ul li.nav-item {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}
#navbar a.nav-link {
  transition: var(--transition);
  display: flex;
  align-items: center;
  padding: 0 1vw;
  height: 100%;
}
#navbar a.nav-link:hover,
#navbar a.nav-link:focus,
#navbar a.nav-link:active {
  color: var(--alt-bg-color);
  text-shadow: 2px 2px #000;
  border-bottom: 0.33em solid var(--alt-bg-color);
  outline: none;
}
#navbar #navbar_brand {
  margin: 0 auto 0 0;
}
#navbar #navbar_brand a {
  padding-left: 0;
  transition: all 1s ease-in-out;
}
#navbar img {
  max-height: calc(var(--navbar-height) - 10px);
}
#navbar #navbar_brand a:hover,
#navbar #navbar_brand a:focus,
#navbar #navbar_brand a:active {
  font-size: 1.03em;
  color: var(--alt-bg-color);
  border-bottom: 2px solid var(--alt-bg-color);
  outline: none;
}
#navbar #nav_open_item {
  cursor: pointer;
  display: none;
  padding-right: 0;
  text-shadow: 1px 1px 1px #000;
  font-size: 2em;
}
#navbar #nav_open_item:hover,
#navbar #nav_open_item:focus,
#navbar #nav_open_item:active {
  font-size: 1.03em;
  color: #fff;
  outline: 2px solid var(--alt-bg-color);
}

/* FOR TRANSPARENT NAVBAR: Enable the .transparent-navbar and .nav-color CSS rules and don't forget to add .transparent-header to the nav element in the HTML. */
#navbar.transparent-navbar {
  background-color: transparent;
  background-image: none;
  position: fixed;
  width: 100%;
  z-index: 200;
  top: 0px;
  text-shadow: 1px 1px 1px #000;
  backdrop-filter: blur(0.7px);
  transition: var(--transition);
}

/* FOR TRANSPARENT NAVBAR: .nav-colo adds background color to the navbar once user scrolls down. No need to add this class in the HTML. Check out the JS to see the scroll how and when the rule is applied. */
/* #navbar.nav-color {
  background-color: #daa520f8;
  box-shadow: 0 1px 5px #555;
} */

#click_blocker {
  display: none;
}
#click_blocker.on {
  display: block;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #00000088;
}

#side_sheet {
  transition: var(--transition);
  width: var(--side-sheet-width);
  max-width: 400px;
  position: fixed;
  right: calc(0px - var(--side-sheet-width));
  top: 0;
  z-index: 200;
  /* background-image: linear-gradient(to bottom, #000000ff, goldenrod); */
  background-color: black;
  /* background-color: var(--main-bg-color); */
  height: 100vh;
  display: none;
}
#side_sheet.open {
  right: 0px;
}

#side_sheet ul {
  list-style: none;
}
#side_sheet li > * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--navbar-height);
}
#paypal_button {
  display: flex;
  justify-content: center;
  align-items: center;
}
#side_sheet .side-nav-link {
  margin: 1px 0;
  transition: var(--transition);
}
#side_sheet .side-nav-link:hover,
#side_sheet .side-nav-link:focus,
#side_sheet .side-nav-link:active {
  background-color: var(--alt-bg-color);
  color: var(--main-bg-color);
  outline: none;
}
#side_sheet #side_nav_brand {
  padding: 0 5%;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  transition: var(--transition);
}
#side_sheet #side_nav_brand:hover,
#side_sheet #side_nav_brand:focus,
#side_sheet #side_nav_brand:active {
  color: var(--alt-bg-color);
}
#side_sheet img {
  max-height: var(--navbar-height);
  margin: auto;
}
#side_sheet #nav_close_item {
  padding: 0 5%;
  margin: 0 8%;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
#side_sheet #nav_close_item:hover,
#side_sheet #nav_close_item:focus,
#side_sheet #nav_close_item:active {
  color: var(--alt-bg-color);
  outline: none;
}

@media (max-width: 768px) {
  #navbar ul li {
    padding: 0;
  }
  /*@ .nav-item > form hides the paypal donate button, since it's not an <a> tag */
  #navbar a.nav-link,
  .nav-item > form {
    display: none;
  }
  #navbar #nav_open_item {
    display: flex;
  }

  #side_sheet {
    display: block;
  }
}

/*# sourceMappingURL=Nav.css.map */
