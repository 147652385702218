.follow-us-banner {
  /* background-color: goldenrod; */
  background-image: linear-gradient(to top, #987316, #daa520);
  color: ivory;
  display: flex;
  flex-wrap: wrap;
  /* padding: 1em; */
  transition: 0.25s ease;
}

.follow-us-banner h2 {
  font-size: clamp(1em ,2em, 3em);
}

.icons-wrapper {
  display: flex;
  margin: 0em auto;
  /* width: clamp(48px 50% 100%); */
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.follow-us-banner > h2 {
  display: block;
  color: ivory;
  font-size: clamp(1em ,2em, 3em);
  margin: 1em auto;
  width: 100%;
  text-align: center;
}

.social-link { /* The <a> tag that wraps the icon component itself. */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0em 1em 2em;
  /* color: azure; */
  text-decoration: none;
}

.social-link *,
.social-link *:visited {
  color: azure;
}

.social-link span {
  margin-top: 0.5em;
}

.handle {
  font-size: 0.75em;
  /* display: none; */
  /* color: transparent; */
}

/* .handle:hover {
  color: white;
} */
